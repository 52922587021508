import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import { OTPField } from "components"
import { LoginSeo } from "components/SEO"
import {
	GoogleAuthProvider,
	RecaptchaVerifier,
	signInWithPopup,
} from "firebase/auth"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import { tokenState, userState } from "recoil/authState"
import AuthService from "services/auth.service"
import CookieService from "services/cookie.service"
import { auth } from "services/firebase"
import packageJson from "../../package.json"

const Login = () => {
	const [loginForm, setLoginForm] = useState({
		email: "",
		password: "",
	})
	const [otpSessionId, setOtpSessionId] = useState(null)
	const [otp, setOtp] = useState([])
	const [inputType, setInputType] = useState("email")
	const [showStep, setShowStep] = useState(false)
	const [passHidden, setPassHidden] = useState(true)
	const setUser = useSetRecoilState(userState)
	const [loading, setLoading] = useState({ api: false, google: false })
	const setTokens = useSetRecoilState(tokenState)
	const resetUser = useResetRecoilState(userState)
	const resetTokens = useResetRecoilState(tokenState)
	const recaptchaRef = useRef()
	const inputRef = useRef()
	const router = useRouter()
	const provider = new GoogleAuthProvider()

	const updateEmailNumber = e => {
		const input = e.target.value
		setLoginForm(prev => ({ ...prev, email: input }))
	}

	const handleContinue = () => {
		const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
		const mobileRegex = /^[0-9]{10}$/
		if (emailRegex.test(loginForm.email)) {
			setInputType("email")
			setShowStep(true)
		} else if (mobileRegex.test(inputRef.current.value)) {
			setInputType("number")
			recaptchaRef.current.click()
		}
	}

	const getOtp = ({ recaptchaToken }) => {
		setLoading({ ...loading, api: true })
		AuthService.getOtp({
			phoneNumber: `+91${inputRef.current.value}`,
			recaptchaToken,
		})
			.then(resp => {
				if (resp.sessionId) {
					setOtpSessionId(resp.sessionId)
					setShowStep(true)
				}
			})
			.catch(err => {
				toast.error(err.message)
			})
			.finally(() => {
				setLoading({ ...loading, api: false })
			})
	}

	const handleSubmit = e => {
		e?.preventDefault()
		if (showStep) {
			if (inputType === "email") login()
			else if (inputType === "number") verifyOtp()
		} else handleContinue()
	}

	const resetAll = () => {
		resetTokens()
		resetUser()
		CookieService.removeTokens()
		setOtpSessionId()
		setOtp("")
		setInputType("email")
		setShowStep(false)
		inputRef.current.value = ""
		setTimeout(router.reload, 1000)
	}

	const verifyRole = user => {
		if (user.role.toLowerCase() === "newtrainer") {
			setUser(user)
			router.push("/")
		} else if (!["trainer", "admin"].includes(user.role.toLowerCase())) {
			toast.error("You dont have permissions to this page")
			resetAll()
		} else {
			setUser(user)
			router.push(router.query.r || "/console")
		}
		setLoading(state => ({ ...state, api: false }))
	}

	const setLuckyOrangeIdentity = userProfile => {
		const user = {
			name: userProfile.name,
			profileImageUrl: userProfile.profileImageUrl,
			uid: userProfile.uid,
			username: userProfile.username,
			email: userProfile.email,
		}
		window.LOQ = window.LOQ || []
		window.LOQ.push([
			"ready",
			async LO => {
				// Or, identify a visitor
				await LO.$internal.ready("visitor")
				LO.visitor.identify(user)
			},
		])
	}

	const verifyOtp = () => {
		setLoading({ ...loading, api: true })
		AuthService.verifyOtp({
			verificationCode: otp.join(""),
			phoneSessionId: otpSessionId,
		}).then(tokens => {
			setTokens(tokens)
			AuthService.getProfile()
				.then(resp => {
					if (resp) {
						verifyRole(resp.user)
						setLuckyOrangeIdentity(resp.user)
					} else toast.error("Oops! Something went wrong")
				})
				.finally(() => {
					setLoginForm({
						email: "",
						password: "",
					})
					setLoading(state => ({ ...state, api: false }))
				})
		})
	}

	const login = e => {
		e?.preventDefault()
		setLoading(state => ({ ...state, api: true }))
		AuthService.login(loginForm).then(tokens => {
			setTokens(tokens)
			AuthService.getProfile()
				.then(resp => {
					if (resp) {
						verifyRole(resp.user)
						setLuckyOrangeIdentity(resp.user)
					} else toast.error("Oops! Something went wrong")
				})
				.finally(() => {
					setLoginForm({
						email: "",
						password: "",
					})
					setLoading(state => ({ ...state, api: false }))
				})
		})
	}

	const googleLogin = e => {
		e?.preventDefault()
		setLoading(state => ({ ...state, google: true }))
		signInWithPopup(auth, provider)
			.then(result => {
				const { uid, photoUrl } = result.user
				AuthService.googleLogin({ uid, photoUrl })
					.then(tokens => {
						setTokens(tokens)
						AuthService.getProfile()
							.then(resp => {
								if (resp) {
									verifyRole(resp.user)
									setLuckyOrangeIdentity(resp.user)
								} else toast.error("Oops! Something went wrong")
							})
							.finally(() =>
								setLoading(state => ({
									...state,
									google: false,
								}))
							)
					})
					.finally(() =>
						setLoading(state => ({ ...state, google: false }))
					)
			})
			.finally(() => setLoading(state => ({ ...state, google: false })))
	}

	useEffect(() => {
		if (CookieService.getLocalAccessToken()) {
			router.push(`/console`)
		}
		try {
			window.recaptchaVerifier = new RecaptchaVerifier(
				"sendCode",
				{
					size: "invisible",
					callback: function (recaptchaToken) {
						// reCAPTCHA solved, send recaptchaToken and phone number to backend.
						getOtp({ recaptchaToken })
					},
				},
				auth
			)
		} catch (error) {
			console.log(error)
		}
		// render the rapchaVerifier.
		window.recaptchaVerifier
			.render()
			.then(function (widgetId) {
				window.recaptchaWidgetId = widgetId
			})
			.catch(function (error) {
				console.log(error)
			})
	}, [])

	return (
		<>
			<LoginSeo />
			<div className="bgSquare flex h-screen flex-col items-center justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
				<div className="sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-12 w-auto"
						src="https://lucdn.letsupgrade.net/logo_lu_0852b077a0.png"
						alt="Your Company"
					/>
					<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
						Sign in to Trainer Console
					</h2>
				</div>

				<div className="mt-8 sm:w-full sm:max-w-md">
					<div className="rounded-lg bg-white py-8 px-4 shadow sm:px-10">
						<form
							className="space-y-6"
							onSubmit={handleSubmit}
						>
							<div>
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Enter Email ID / Mobile Number
								</label>
								<div className="mt-1">
									<input
										id="email"
										required
										autoComplete="email"
										autoFocus
										className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lu-500 focus:outline-none focus:ring-lu-500 sm:text-sm"
										placeholder="john.doe@google.com"
										// value={loginForm.email}
										onChange={updateEmailNumber}
										ref={inputRef}
									/>
								</div>
							</div>

							{showStep ? (
								inputType === "email" ? (
									<div>
										<label
											htmlFor="password"
											className="block text-sm font-medium text-gray-700"
										>
											Password
										</label>
										<div className="relative mt-1">
											<input
												id="password"
												name="password"
												type={
													passHidden
														? "password"
														: "text"
												}
												autoComplete="current-password"
												required
												className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lu-500 focus:outline-none focus:ring-lu-500 sm:text-sm"
												placeholder="********"
												value={loginForm.password}
												onChange={e =>
													setLoginForm(prev => ({
														...prev,
														password:
															e.target.value,
													}))
												}
											/>
											<span
												className="absolute inset-y-0 right-2 flex items-center justify-center"
												onClick={() =>
													setPassHidden(!passHidden)
												}
											>
												{passHidden ? (
													<EyeOffIcon className="h-6 w-6 text-gray-600" />
												) : (
													<EyeIcon className="h-6 w-6 text-gray-600" />
												)}
											</span>
										</div>
									</div>
								) : (
									<div>
										<label
											htmlFor="otp"
											className="block text-sm font-medium text-gray-700"
										>
											OTP
										</label>
										<div className="mt-1">
											<OTPField
												{...{ setOtp, handleSubmit }}
											/>
										</div>
									</div>
								)
							) : null}

							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										id="remember-me"
										name="remember-me"
										type="checkbox"
										className="h-4 w-4 rounded border-gray-300 text-lu-600 focus:ring-lu-500"
									/>
									<label
										htmlFor="remember-me"
										className="ml-2 block text-sm text-gray-900"
									>
										Remember me
									</label>
								</div>

								<div className="text-sm">
									<a
										href="https://letsupgrade.in/forgot"
										target="_blank"
										className="font-medium text-lu-600 hover:text-lu-500"
									>
										Forgot your password?
									</a>
								</div>
							</div>

							<div>
								{showStep ? (
									<button
										type="submit"
										className="flex w-full justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-0"
									>
										{loading.api ? (
											<div className="flex items-center justify-center ">
												<div className="h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
											</div>
										) : (
											"Sign In"
										)}
									</button>
								) : (
									<button
										type="button"
										className="flex w-full justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-0 disabled:ring-2"
										onClick={handleContinue}
									>
										{loading.api ? (
											<div className="flex items-center justify-center ">
												<div className="h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
											</div>
										) : (
											"Continue"
										)}
									</button>
								)}
							</div>
						</form>

						<div className="mt-6">
							<div className="relative">
								<div className="absolute inset-0 flex items-center">
									<div className="w-full border-t border-gray-300" />
								</div>
								<div className="relative flex justify-center text-sm">
									<span className="bg-white px-2 text-gray-500">
										Or continue with
									</span>
								</div>
							</div>

							<div className="mt-6 grid grid-cols-1">
								<button
									className="inline-flex w-full items-center justify-center space-x-4 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
									onClick={googleLogin}
								>
									{loading.google ? (
										<div className="flex items-center justify-center ">
											<div className="h-4 w-4 animate-spin rounded-full border-b-2 border-gray-900" />
										</div>
									) : (
										<>
											<img
												src="https://img.icons8.com/color/48/000000/google-logo.png"
												width="25"
												className="mr-4"
											/>
											Sign In with Google
										</>
									)}
								</button>
							</div>
						</div>
						<div className="flex items-center justify-center px-4 py-2">
							<Link href="/register">
								<a className="text-xs text-lu-500">
									Dont't Have an Account?
								</a>
							</Link>
						</div>
					</div>
					<div className="flex items-center justify-center px-4 py-2">
						<span className="text-xs text-gray-300">
							v{packageJson.version}
						</span>
					</div>
				</div>
			</div>
			<div className="recaptcha-container">
				<button
					id="sendCode"
					className="hidden"
					ref={recaptchaRef}
				>
					Send Code
				</button>
			</div>
		</>
	)
}

// Login.auth = true
export default Login
